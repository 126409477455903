import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/rezydencja-swierkowa"

const Investment = ({ data, location }) => {
  const title = "Смерекова Резиденція"
  const allApartments = data.allWpApartmentRezydencjaSwierkowa.nodes

  return (
    <Layout
      location={location}
      seo={{
        title: "Смерекова Резиденція",
        description:
          "Ідеальне місце для сімей. 133 квартири в 10 хвилинах від центру Радома, площею 25-74 м2. Безпосередньо від авторитетного забудовника.",
        image: withPrefix("/rsk-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Радом", url: "/ua/kvartyry/radom" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} apartmentList={allApartments} />
      <Nav
        title={title}
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      <Apartments
        apartmentList={allApartments}
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <ContactForm
        formType="sale"
        investment="Rezydencja Świerkowa"
        investmentRaw="rezydencja_swierkowa"
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartmentRezydencjaSwierkowa(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          investment
          type
          apartmentNumber
          investmentBuilding
          buildingNumber
          area
          floor
          location
          price
          priceForSquareMeter
          roomsnumber
          status
          visualization2d {
            sourceUrl
          }
          placesList {
            area
            name
          }
        }
      }
    }
  }
`

export default Investment
